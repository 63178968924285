import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dygraph from "dygraphs";
import "dygraphs/dist/dygraph.min.css";
import * as graphsAndMapConsts from "../../../consts/translations";
import * as globalConst from "../../../consts/globalConst";
import {Context} from "../../../services/Context";
import img_eye from "../../../assets/images/eye.svg";
import img_eye_off from "../../../assets/images/eye-off.svg";
import img_toggle from "../../../assets/images/refresh-cw.svg";
import { API_URL_BRIO } from "../../../services/Api";

const months = graphsAndMapConsts.months;
const monthsComplete = graphsAndMapConsts.monthsComplete;
const sizeObject = globalConst.sizeObject;

class MonthlyGraph extends Component {
  static contextType = Context

  getTranslation(key) {
    let contentLang = {
      FR: {
        code: "FR",
        titleMonthly: "Variation mensuelle ",
        labels: "labels",
        tooltipExport: "Exporter le graphique au format CSV"
      },
      EN: {
        code: "EN",
        titleMonthly: "Monthly variation in ",
        labels: "labels_en",
        tooltipExport: "Export Graph as CSV"
      }
    };
    return contentLang[this.props.language][key];
  }

  constructor(props) {
    super(props);
    this.meta = {
      "axes": {
        "x" : {
          axisLabelWidth: 70,
          pixelsPerLabel: 20,
          axisLabelFormatter: function (d) {
            if(d === 0 || d === 13) return '';
            return months[props.language][d - 1];
          },
          valueFormatter: function (d) {
            if(d === 0 || d === 13) return ''; 
            return monthsComplete[props.language][d - 1];
          },
        }
      },
      "labels": props.graphicsData.labels,
      "legend": "follow",
      "series":  props.graphicsData.series,
      "strokeWidth": 2,
      "title": this.getTranslation('titleMonthly')+' '+this.props.periodString,
      "labelsSeparateLines": true,
      "yLabelWidth": 0
    };
    this.data = props.graphicsData.data;
  }

  axisLabelFormatter (d) {
    if(d === 0 || d === 13) return '';
    return months[this.lang][d - 1];
  }

  valueFormatter (d) {
    if(d === 0 || d === 13) return ''; 
    return monthsComplete[this.lang][d - 1];
  }

  createGraph() {
    var vm = this;
    if(document.getElementById('chartMonth')) {
      return new Promise(function(resolve, reject) {
        vm.graphDygraph = new Dygraph(
          document.getElementById("chartMonth"),
          vm.data,
          vm.meta
        );
        if(sizeObject(vm.meta.series) > 1){
          vm.graphDygraph.ready(function(){
            resolve();
            var labels = vm.graphDygraph.getLabels();
            // remove the first x-axis element
            labels.shift();
          });
        }
        //monthForExport
        const optionsForExport = JSON.parse(JSON.stringify(vm.meta));
        optionsForExport["title"] = null;
        delete optionsForExport.title;
        vm.graphForExport = new Dygraph(
          document.getElementById("monthForExport"),
          vm.data,
          optionsForExport
        );
      });
    }
  }

  render() {
    const renderLabels = ()=>{
        return (
          <table>
            <tbody>
            <tr id="month_toggle">
              <td></td>
              <td><img onClick={() => {this.toggleAll()}} style={{cursor:'pointer',height:'1em'}} src={img_toggle} alt=""/></td>
            </tr>
            {Object.entries(this.meta.series).map(([key, value]) => (
              <tr key={key}>
                <td className="scen_months" style={{color:value.color}}><div className="dygraph-legend-line" style={{borderBottomColor: value.color}}></div>{key}</td>
                <td><img alt="toggle visibility" id={"eye"+key} onClick={() => {this.toggleVisible(key)}} style={{cursor:'pointer',height:'1em'}} src={img_eye}/></td>
              </tr>
            ))}
          </tbody>
          </table>
        )
    }
    return (
      <div className="container-graphic">
        <div className="monthly-graph">
          <div id="monthUnit" className="helptext">{this.props.unit}</div>
          <div id="chartMonth"></div>
        </div>
        <div id="legend_monthly">
          {renderLabels()}
        </div>
        <OverlayTrigger placement="bottom-end" overlay={<Tooltip>{this.getTranslation('tooltipExport')}</Tooltip>}>
          <div className="btn-small export" onClick={this.handleExport}></div>
        </OverlayTrigger>
        <div id="monthForExport" style={{width: 1100, height: 690, position: 'absolute', left: '100%', top: '100%'}}></div>
      </div>
    );
  }

  handleExport = () => {
    const [selecteds] =this.context;
    const location = selecteds.type === 'pxl' || selecteds.sector === 'GBL' ? `&lat=${this.props.place.lat}&lon=${this.props.place.lon}`:`&id=${this.props.idPlace}`;
    const type = selecteds.sector === 'GBL' ? '&type=pxl': '&type='+selecteds.type;
    const urlCSV = API_URL_BRIO + "chart/month.csv?" +
    `sector=${selecteds.sector}&model=${selecteds.model}&period=${selecteds.period}&scenario=${selecteds.scenario}&season=${selecteds.season}&variable=${selecteds.variable}`+
    location + type;
    window.location.href = urlCSV;
  }

  toggleVisible(l){
      var i = this.graphDygraph.indexFromSetName(l)-1;
      var a = document.getElementById('eye'+l);
      var v = this.graphDygraph.visibility()[ i ];
      this.graphDygraph.setVisibility(i, !v);
      a.src = v?img_eye_off:img_eye;
  }

  toggleAll(){
    var vm = this;
    var yl = vm.graphDygraph.getLabels();
    yl.shift();
    yl.forEach(function (l){
      vm.toggleVisible(l);
    });
  }

  componentDidUpdate(pProps){
    var vm = this;
    if (pProps.graphicsData !== this.props.graphicsData) {
      this.graphDygraph.updateOptions({file : this.props.graphicsData.data});
      this.graphForExport.updateOptions({file : this.props.graphicsData.data});
    }
    if (pProps.language !== this.props.language) {
      const newTitle = this.getTranslation('titleMonthly')+' '+this.props.periodString;
      vm.graphDygraph["lang"] = vm.props.language;
      vm.graphForExport["lang"] = vm.props.language;
      vm.graphDygraph.updateOptions({title : newTitle, axes : {x:{valueFormatter: vm.valueFormatter, axisLabelFormatter: vm.axisLabelFormatter}}});
      vm.graphForExport.updateOptions({title : newTitle, axes : {x:{valueFormatter: vm.valueFormatter, axisLabelFormatter: vm.axisLabelFormatter}}});
    }
    if(this.props.isDoubleView) {
      setTimeout(function() {
        vm.graphDygraph.resize();
      }, 500);
    }
  }

  async componentDidMount() {
    var vm = this;
    await vm.createGraph();
    if(vm.graphDygraph) setTimeout(function() {vm.graphDygraph.resize();}, 500);
  }
}

export default MonthlyGraph;