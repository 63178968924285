import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

export default function Loading() {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="wrap-shadow" id="loading" >
        <div className="uil-default-css">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className="loading-text">Loading</div>
      </div>
    </div>
    )
  );
};