import React , {useState, createContext} from 'react';

export const Context = createContext();

export const Provider = props => {
    const [selecteds,setSelecteds]=useState({
      sector: localStorage.getItem('brio_sector') || "GBL",
      season: localStorage.getItem('brio_season') || "ANN",
      type: localStorage.getItem('brio_type') || "pxl",
      variable: localStorage.getItem('brio_variable') || "cumul",
      scenario: localStorage.getItem('brio_scenario') || "ssp585",
      model: localStorage.getItem('brio_model') || "CNRM-ESM2-1",
      period: localStorage.getItem('brio_period') || "2071",
      anom: localStorage.getItem('brio_anom') || "0"
    });

    return(
      <Context.Provider value={[selecteds, setSelecteds]}>
        {props.children}
      </Context.Provider>
    );
}