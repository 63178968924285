import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dygraph from "dygraphs";
import "dygraphs/dist/dygraph.min.css";
import {Context} from "../../../services/Context";
import img_eye from "../../../assets/images/eye.svg";
import img_eye_off from "../../../assets/images/eye-off.svg";
import img_toggle from "../../../assets/images/refresh-cw.svg";
import { API_URL_BRIO } from "../../../services/Api";

class YearlyGraph extends Component {
  static contextType = Context

  getTranslation(key) {
      let contentLang = {
      FR: {
          code: "FR",
          titleYearly: "Évolution annuelle",
          labels: "labels",
          series: "series",
          tooltipExport: "Exporter le graphique au format CSV"
      },
      EN: {
          code: "EN",
          titleYearly: "Yearly evolution",
          labels: "labels_en",
          series: "series_en",
          tooltipExport: "Export Graph as CSV"
      }
      };
      return contentLang[this.props.language][key];
  }

  sortObject(o) { // Quitar y ordenar desde el backend
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
  }

  constructor(props) {
    super(props);
    this.meta = {
      "axes": {},
      "labels": props.graphicsData[this.getTranslation('labels')],
      "legend": "follow",
      "series": props.series[this.getTranslation('series')],
      "showRangeSelector": true,
      "sigma": 1,
      "strokeWidth": 2,
      "title": this.getTranslation('titleYearly'),
      "labelsSeparateLines": true,
      "yLabelWidth": 0,
      "interactionModel": Dygraph.defaultInteractionModel
    };
  }
  
  createGraph() {
    var vm = this;
    if(document.getElementById('chartYear')){
      return new Promise(function(resolve, reject) {
        vm.graphDygraph = new Dygraph(
          document.getElementById("chartYear"),
          vm.props.graphicsData.data,
          vm.meta
        );
        //yearForExport
        const optionsForExport = JSON.parse(JSON.stringify(vm.meta));
        optionsForExport.title = null;
        optionsForExport.showRangeSelector = false;
        delete optionsForExport.interactionModel;
        delete optionsForExport.title;
        vm.graphForExport = new Dygraph(
          document.getElementById("yearForExport"),
          vm.props.graphicsData.data,
          optionsForExport
        );
      });
    }
  }

  render() {
    const renderLabels = ()=>{
      return (
        <table>
          <tbody>
          <tr id="year_toggle">
            <td></td>
            <td><img onClick={() => {this.toggleAll()}} style={{cursor:'pointer',height:'1em'}} src={img_toggle} alt=""/></td>
          </tr>
          {Object.entries(this.sortObject(this.props.series[this.getTranslation('series')])).map(([key, value]) => (
            <tr key={key}>
              <td 
              className="scen_years" style={{color:value.color}}>
                <div className="dygraph-legend-line" style={{borderBottomColor: value.color, borderBottomStyle: value.hasOwnProperty('strokePattern')?'dotted':'solid'}}></div>{key}</td>
              <td>
              <img alt="toggle visibility" id={"eye"+key+"y"} onClick={() => {this.toggleVisible(key)}} style={{cursor:'pointer',height:'1em'}} src={img_eye}/></td>
            </tr>
          ))}
        </tbody>
        </table>
      )
    }
    return (
      <div className="container-graphic">
        <div className="yearly-graph">
          <div id="yearUnit" className="helptext">{this.props.unit}</div>
          <div id="chartYear"></div>
        </div>
        <div id="legend_yearly">
          {renderLabels()}
        </div>
        <OverlayTrigger placement="bottom-end" overlay={<Tooltip>{this.getTranslation('tooltipExport')}</Tooltip>}>
          <div className="btn-small export exp-year" onClick={this.handleExport}></div>
        </OverlayTrigger>
        <div id="yearForExport" style={{width: 1100, height: 690, position: 'absolute', left: '100%', top: '100%'}}></div>
      </div>
    );
  }

  handleExport = () => {
    const [selecteds] =this.context;
    const location = selecteds.type === 'pxl' || selecteds.sector === 'GBL' ? `&lat=${this.props.place.lat}&lon=${this.props.place.lon}`:`&id=${this.props.idPlace}`;
    const type = selecteds.sector === 'GBL' ? '&type=pxl': '&type='+selecteds.type;
    const urlCSV = API_URL_BRIO + "chart/year.csv?" +
    `sector=${selecteds.sector}&model=${selecteds.model}&period=${selecteds.period}&scenario=${selecteds.scenario}&season=${selecteds.season}&variable=${selecteds.variable}`+
    location + type;
    window.location.href = urlCSV;
  }

  toggleVisible(l){
      var i = this.graphDygraph.indexFromSetName(l)-1;
      var a = document.getElementById('eye'+l+'y');
      var v = this.graphDygraph.visibility()[ i ];
      this.graphDygraph.setVisibility(i, !v);
      a.src = v?img_eye_off:img_eye;
  }

  toggleAll(){
    var vm = this;
    var yl = vm.graphDygraph.getLabels();
    yl.shift();
    yl.forEach(function (l){
      vm.toggleVisible(l);
    });
  }

  componentDidUpdate(pProps){
    var vm = this;
    if (pProps.graphicsData !== this.props.graphicsData) {
        this.graphDygraph.updateOptions({file : this.props.graphicsData.data});
        this.graphForExport.updateOptions({file : this.props.graphicsData.data});
      }
      if (pProps.language !== this.props.language) {
        vm.graphDygraph.updateOptions({title : this.getTranslation('titleYearly'), labels: this.props.graphicsData[this.getTranslation('labels')], });
        vm.graphDygraph.updateOptions({series: this.props.series[this.getTranslation('series')]});
      }
    if(this.props.isDoubleView) {
      setTimeout(function() {
        vm.graphDygraph.resize();
      }, 500);
    }
  }

  componentDidMount() {
    var vm = this;
    vm.createGraph();
    if(vm.graphDygraph) setTimeout(function() {vm.graphDygraph.resize();}, 500);
  }
}

export default YearlyGraph;
