import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {Context} from "../../services/Context";
import L from "leaflet";
import leafletImage from "leaflet-image";
import html2canvas from 'html2canvas';
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import logo_brio from '../../assets/images/logo-brio-color.png';
import logo_afd from '../../assets/images/logo-afd-hd.png';
import logo_meteo from '../../assets/images/logo-meteofrance-hd.png';
import logo_coi from '../../assets/images/logo-coi-hd.png';
import * as globalConst from "../../consts/globalConst";
import { trackPromise } from 'react-promise-tracker';
import { API_URL_BRIO } from "../../services/Api";

const isEmpty = globalConst.isEmpty;
require('../../consts/Lato-Bold-bold');
require('../../consts/Lato-Regular-normal');
require('../../consts/CourierPrime-Regular-normal');

export default class GlobalMap extends Component {

  static contextType = Context
  
  getTranslation(keyDict, forBaselayer) {
    let contentLang = {
      FR: {
        code: "FR",
        title: "title",
        name: "name",
        value: "Valeur de l'emplacement",
        exportTiff: "Exporter la carte au format TIF",
        exportGeoJson: "Exporter la carte au format GeoJson",
        screenshot: "Faire une capture d'écran de la carte",
        reset: "Réinitialiser la position et le zoom",
        satelitalView: "Satellite",
        streetView : "Street view",
        topoView : "Relief",
        transparency: "Transparence",
        titleMonthly: "Variation mensuelle ",
        titleYearly: "Évolution annuelle"
      },
      EN: {
        code: "EN",
        title: "title_en",
        name: "name_en",
        value: "Value for location",
        exportTiff: "Export Map as TIF",
        exportGeoJson: "Export Map as GeoJson",
        screenshot: "Take a screenshot of the map",
        reset: "Reset position and zoom",
        satelitalView: "Satelital view",
        streetView : "Street view",
        topoView : "Topographic view",
        transparency: "Transparency",
        titleMonthly: "Monthly variation in ",
        titleYearly: "Yearly evolution"
      }
    };
    if(forBaselayer) {
      const objectLang = contentLang[this.props.language];
      return Object.keys(objectLang).find(key => objectLang[key] === keyDict);
    } else {
      return  contentLang[this.props.language][keyDict];
    }
  }

  render() {
    const [selecteds] =this.context;
    const valueName = selecteds.anom === "0" || selecteds.anom === 0 ? "abs" : "dif";
    const valueArray = this.getScales(this.props.sectorParameters.variables.data, valueName);
    const arrayColors = valueArray[(selecteds.season !== "ANN"?"season":"annual")].colors.slice().reverse();
    const arrayRange = valueArray[(selecteds.season !== "ANN"?"season":"annual")].range.slice().reverse();
    const unit = this.getItemPropInArray(this.props.sectorParameters.variables.data, 'variable', selecteds.anom === "0" || selecteds.anom === 0 ? 'unit':'unit_anom');
    const handleExport = () => {
      const urlTiff = API_URL_BRIO+'map/?export&type=pxl&sector='+
      `${selecteds.sector}&season=${selecteds.season}&variable=${selecteds.variable}&scenario=${selecteds.scenario}&period=${selecteds.period}`+(selecteds.anom === "1"?'&anom':'');
      window.open(urlTiff, "_blank");
    }
    const handleResetCenterZoom = () => {
      this.map.setView([-15, 51.63],5);
      this.map.invalidateSize(true);
    }
    const updateOpacity = (e) => {
      this.props.changeTransparency(e.target.value);
      this.geoMap.eachLayer(function(layer){
          layer.setStyle({
            fillOpacity: 1-e.target.value
          });
      });
    }
    const handleScreenshot = (e) => {
      var namePNG = (selecteds.anom === "0" || selecteds.anom === 0 ? '':'anom_') +
      `${selecteds.variable}_${selecteds.sector}_${(selecteds.scenario).toUpperCase()}_${this.getItemNameInArray(this.props.inputOptions.periods.data, 'period')}`
      + (selecteds.season === "ANN"? '':'_'+selecteds.season);
      var map = document.getElementById('map_gbl');
      document.getElementsByClassName('leaflet-control-container')[0].style.display = 'none';
      //console.log("screenshot");
      domtoimage.toPng(map)
      .then(function(dataUrl) {
        var link = document.createElement('a');
        link.download = namePNG +'-screenshot.png';
        link.href = dataUrl;
        link.click();
        document.getElementsByClassName('leaflet-control-container')[0].style.display = 'block';
      }).catch(function (error) {
        console.error("screenshot domtoimage error", error);
        document.getElementsByClassName('leaflet-control-container')[0].style.display = 'block';
      });
    }

    return (
      <>
        <div id="map_gbl" className="map-container">
          { isEmpty(this.props.sectorParameters.variables.data) ?
          '':
            <div className="legend" id="legend_map">
              <div className='unit'>{unit}</div>
              <div className="colors">
              {arrayColors.map((color, index) =>
                <div key={index} style={{backgroundColor:color}}></div>
              )}
              </div>
              <div className="texts">
              {arrayRange.map((value, index) =>
                <div key={index} >{value}</div>
              )}
              </div>
            </div>
          }
        </div>
        <OverlayTrigger placement="left" overlay={<Tooltip>{this.getTranslation("transparency")+" : "+this.props.transparencyMap*100+"%"}</Tooltip>}>
          <div className="range-container"><input id="range_gbl" className="range" type="range" min="0" max="1" step="0.1" value={this.props.transparencyMap} onChange={updateOpacity}/></div>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom-end" overlay={<Tooltip>{this.getTranslation("exportTiff")}</Tooltip>}>
          <div className="btn-small export" onClick={handleExport}></div>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom-end" overlay={<Tooltip>{this.getTranslation("screenshot")}</Tooltip>}>
          <div className="btn-small screenshot" onClick={handleScreenshot}></div>
        </OverlayTrigger>
        <OverlayTrigger placement="top-end" overlay={<Tooltip>{this.getTranslation("reset")}</Tooltip>}>
          <div className="btn-small reset-zoom" onClick={handleResetCenterZoom}></div>
        </OverlayTrigger>
      </>
      );
  }

  exportTo = (format) => {
    if(format === 'pdf') {
      trackPromise(this.createPDF());
    } else {
      trackPromise(this.createPNGSVG(format));
    }
  }

  async createPNGSVG(format) {
    const [selecteds] =this.context;
    var vm = this;
    var widthMap = 2025;
    var heightMap = 1325;
    var layer_img = L.layerGroup();
    var coord = [];

    var geoMap = L.geoJson(vm.props.mapData, vm.styleMapData(true));
    var layerDiv = L.geoJson(vm.props.mapDivisions, this.styleGeoDivisions(true));
    layer_img.addLayer(geoMap);
    layer_img.addLayer(layerDiv);

    await layer_img.getLayers()[0].eachLayer(function(layer) {
      coord.splice(0, 0, layer);
    });
    var group = new L.featureGroup(coord);

    var contentPNG = document.createElement("div");
    contentPNG.style.width = (widthMap + 200)+'px';
    contentPNG.style.height = heightMap+'px';
    contentPNG.classList.add("png-container");
    document.body.appendChild(contentPNG);

    var mapElement = document.createElement("div");
    mapElement.style.width = widthMap+'px';
    mapElement.style.height = heightMap+'px';
    mapElement.classList.add("clone-map");
    contentPNG.appendChild(mapElement);

    var mapNew = L.map(mapElement, {
      attributionControl: false,
      zoomControl: false,
      fadeAnimation: false,
      zoomAnimation: false,
      preferCanvas: true,
      layers: [layer_img]
    });

    mapNew.fitBounds(group.getBounds()).setZoom(6);

    // Legend image url
    var legend_map = document.getElementById('legend_map').cloneNode(true);
    legend_map.classList.add('clone-legend');
    contentPNG.appendChild(legend_map);

    // Name image
    var namePNG = (selecteds.anom === "0" || selecteds.anom === 0 ? '':'anom_') +
    `${selecteds.variable}_${selecteds.sector}_${(selecteds.scenario).toUpperCase()}_${this.getItemNameInArray(this.props.inputOptions.periods.data, 'period')}`
    + (selecteds.season === "ANN"? '':'_'+selecteds.season);

    if(format === "png") {
      const urlPNG = await domtoimage.toPng(contentPNG, { width: widthMap+200, height: heightMap, bgcolor: 'transparent'})
      .then(function(dataUrl) {
        var link = document.createElement('a');
        link.download = namePNG +'.png';
        link.href = dataUrl;
        link.click();
      }).catch(function (error) {
        console.error("gbl png domtoimage error", error);
      });
    } else {
      const urlSVG = await domtoimage.toSvg(contentPNG, { width: widthMap+200, height: heightMap, bgcolor: 'transparent'})
      .then(function(dataUrl) {
        var link = document.createElement('a');
        link.download = namePNG +'.svg';
        link.href = dataUrl;
        link.click();
      }).catch(function (error) {
        console.error("gbl svg domtoimage error", error);
      });
    }
    document.body.removeChild(contentPNG);
  }

  getItemNameInArray (array, nameClass, capitalize = false) {
    const [selecteds] =this.context;
    const item = array.find(dataItem => dataItem.code.toString() === selecteds[nameClass]);
    return capitalize ? item[this.getTranslation('name')].charAt(0).toUpperCase() + item[this.getTranslation('name')].slice(1): item[this.getTranslation('name')];
  }

  async createPDF() {
    function calculateHeightAspectRatioFitByWidth(oldWidth, oldHeight, newWidth) {
      var aspectRatio = ( oldWidth / oldHeight );
      return newWidth / aspectRatio;
    }
    var vm = this;
    const [selecteds] =this.context;
    var widthMap = 2025;
    var heightMap = 1325;
    var monthForExport = document.getElementById('monthForExport');
    var yearForExport = document.getElementById('yearForExport');
    
    var season = vm.getItemNameInArray(vm.props.inputOptions.seasons.data, 'season', true);
    var variable = vm.getItemNameInArray(vm.props.sectorParameters.variables.data, 'variable', true);
    var model = vm.props.inputOptions.model.data[0][vm.getTranslation('name')];
    var scenario = vm.getItemNameInArray(vm.props.inputOptions.scenarios.data, 'scenario', true);
    var period = vm.getItemNameInArray(vm.props.inputOptions.periods.data, 'period', true);
    var value = vm.getItemNameInArray(vm.props.inputOptions.anom.data, 'anom', true);

    var seasonTitle = vm.props.inputOptions.seasons[vm.getTranslation('title')];
    var variableTitle = vm.props.sectorParameters.variables[vm.getTranslation('title')];
    var modelTitle = vm.props.inputOptions.model[vm.getTranslation('title')];
    var scenarioTitle = vm.props.inputOptions.scenarios[vm.getTranslation('title')];
    var periodTitle = vm.props.inputOptions.periods[vm.getTranslation('title')];
    var valueTitle = vm.props.inputOptions.anom[vm.getTranslation('title')];

    var layer_img = L.layerGroup();
    var coord = [];
    var vistaSatelite = L.tileLayer(
      "//server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    {
      attribution:
        "Tiles &copy; Esri &mdash; Sources: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
    }
    ),
    vistaMapa = L.tileLayer(
      "//server.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      {
        attribution:
          "Tiles &copy; Esri &mdash; Sources: Esri, HERE, Garmin, USGS, Intermap, INCREMENT P, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), MapmyIndia, © OpenStreetMap contributors, and the GIS User Community",
      }
    ),
    vistaTopo = L.tileLayer(
      "//server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
      {
        attribution:
          "Tiles &copy; Esri &mdash; Sources: Esri, HERE, Garmin, Intermap, INCREMENT P, GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), swisstopo, MapmyIndia, © OpenStreetMap contributors, GIS User Community",
      }
    );
    var baseLayers = {};
    baseLayers[this.getTranslation("satelitalView")] = vistaSatelite;
    baseLayers[this.getTranslation("streetView")] = vistaMapa;
    baseLayers[this.getTranslation("topoView")] = vistaTopo;

    var baselayer = baseLayers[this.getTranslation(this.props.baselayer)];

    
    var geoMap = L.geoJson(vm.props.mapData, vm.styleMapData(true));
    var layerDiv = L.geoJson(vm.props.mapDivisions, this.styleGeoDivisions(true));
    layer_img.addLayer(geoMap);
    layer_img.addLayer(layerDiv);

    await layer_img.getLayers()[0].eachLayer(function(layer) {
      coord.splice(0, 0, layer);
    });
    var group = new L.featureGroup(coord);

    const mapElement = document.createElement("div");
    mapElement.style.width = widthMap+'px';
    mapElement.style.height = heightMap+'px';
    mapElement.classList.add("clone-map");
    document.body.appendChild(mapElement);

    const mapNew = L.map(mapElement, {
      attributionControl: false,
      zoomControl: false,
      fadeAnimation: false,
      zoomAnimation: false,
      zoom: 6,
      preferCanvas: true,
      layers: [layer_img,baselayer]
    });

    mapNew.fitBounds(group.getBounds()).setZoom(6);

    if(vm.props.val) {
      L.marker()
      .setLatLng(vm.latlng)
      .addTo(mapNew);
    }
    
    // Legend image url
    const legend_map = document.getElementById('legend_map').cloneNode(true);
    legend_map.classList.add('clone-legend');
    document.body.appendChild(legend_map);
    var heightLegend = legend_map.offsetHeight;
    var legendCanvas = await html2canvas(legend_map);
    var legendURL = legendCanvas.toDataURL('image/jpeg', 1.0);
    document.body.removeChild(legend_map);


    // Dygraphs
    if(monthForExport) {
      var heightMonth = monthForExport.offsetHeight;
      var widthMonth = monthForExport.offsetWidth;
      var monthCanvas = await html2canvas(monthForExport);
      var monthURL = monthCanvas.toDataURL('image/jpeg', 1.0);

      var canvas_month = monthForExport.querySelector("canvas");
      var canvasMonthURL = canvas_month.toDataURL();

      var scenarioMonthTexts = document.getElementsByClassName('scen_months');
    }
    if(yearForExport) {
      var heightYear = yearForExport.offsetHeight;
      var widthYear = yearForExport.offsetWidth;
      var yearCanvas = await html2canvas(yearForExport);
      var yearURL = yearCanvas.toDataURL('image/jpeg', 1.0);

      var canvas_year = yearForExport.querySelector("canvas");
      var canvasYearURL = canvas_year.toDataURL();

      var scenarioYearTexts = document.getElementsByClassName('scen_years');
    }
    //const mapURL = await domtoimage.toJpeg(mapElement, { widthMap, heightMap });
    leafletImage(mapNew, async function(err, canvas) {
      var mapURL = canvas.toDataURL('image/jpeg', 1.0);
      const doc = new jsPDF('l', 'mm', [297, 210]);

      // Logos 
      doc.addImage(logo_brio, 'PNG', 10, 5, 36.37, 12,  NaN, 'FAST');
      doc.addImage(logo_afd, 'PNG', 297-10-37.23, 5, 37.23, 12,  NaN, 'FAST');
      doc.addImage(logo_meteo, 'PNG', 297-10-37.23-5-25.2, 5, 25.2, 12,  NaN, 'FAST');
      doc.addImage(logo_coi, 'PNG', 297-10-37.23-5-25.2-5-35.64, 5, 35.64, 12,  NaN, 'FAST');

      doc.setTextColor(96,96,96);
      doc.setFontSize(18);
      doc.setFont('Lato-Regular', 'normal');
      doc.text("Indianocéanie", 150, 27, 'center');

      const heightImgMap = calculateHeightAspectRatioFitByWidth(widthMap,heightMap, 227);
      doc.addImage(mapURL, 'JPEG', 297-20-227, 33, 227, heightImgMap, NaN, 'SLOW');
      const heightImgLegend = calculateHeightAspectRatioFitByWidth(70,heightLegend, 20);
      doc.addImage(legendURL, 'JPEG', 20, heightImgMap+33-heightImgLegend, 20, heightImgLegend,  NaN, 'SLOW');
      doc.setFontSize(14);

      var namePDF = (selecteds.anom === "0" || selecteds.anom === 0 ? '':'anom_') +
    `${selecteds.variable}_${selecteds.sector}_${(selecteds.scenario).toUpperCase()}_${vm.getItemNameInArray(vm.props.inputOptions.periods.data, 'period')}`
    + (selecteds.season === "ANN"? '':'_'+selecteds.season);

      // Info Footer variables selecteds 
      doc.setFontSize(10);
      doc.setTextColor(96,96,96);
      doc.setFont('Lato-Bold', 'bold');
      doc.text(seasonTitle, 10+40, heightImgMap+33+13, 'center');
      doc.text(variableTitle, 10+80, heightImgMap+33+13, 'center');
      doc.text(modelTitle, 10+120, heightImgMap+33+13, 'center');
      doc.text(scenarioTitle, 10+160, heightImgMap+33+13, 'center');
      doc.text(periodTitle, 10+200, heightImgMap+33+13, 'center');
      doc.text(valueTitle, 10+240, heightImgMap+33+13, 'center');
      doc.setFontSize(8);
      doc.setFont('Lato-Regular', 'normal');
      doc.text(season, 10+40, heightImgMap+33+13+5, 'center');
      doc.text(variable, 10+80, heightImgMap+33+13+5, 'center');
      doc.text(model, 10+120, heightImgMap+33+13+5, 'center');
      doc.text(scenario, 10+160, heightImgMap+33+13+5, 'center');
      doc.text(period, 10+200, heightImgMap+33+13+5, 'center');
      doc.text(value, 10+240, heightImgMap+33+13+5, 'center');


      if(monthForExport){
        // New page
        doc.addPage();
        // Logos 
        doc.addImage(logo_brio, 'PNG', 10, 5, 36.37, 12,  NaN, 'FAST');
        doc.addImage(logo_afd, 'PNG', 297-10-37.23, 5, 37.23, 12,  NaN, 'FAST');
        doc.addImage(logo_meteo, 'PNG', 297-10-37.23-5-25.2, 5, 25.2, 12,  NaN, 'FAST');
        doc.addImage(logo_coi, 'PNG', 297-10-37.23-5-25.2-5-35.64, 5, 35.64, 12,  NaN, 'FAST');

        doc.setTextColor(96,96,96);
        doc.setFontSize(18);
        doc.setFont('Lato-Regular', 'normal');
        doc.text(document.getElementById('title_graphs').textContent, 150, 27, 'center');
        doc.setFontSize(15);
      
        doc.text(vm.getTranslation("titleMonthly")+period, 150, 36, 'center');
        const heightImgMonth = calculateHeightAspectRatioFitByWidth(widthMonth,heightMonth, 220);

        doc.addImage(monthURL, 'JPEG', 32, 43, 220, heightImgMonth, NaN, 'SLOW');
        doc.addImage(canvasMonthURL, 'PNG', 32, 43, 220, heightImgMonth, NaN, 'SLOW');

        var lineLegendMonth = 43;
        for (var i = 0; i < scenarioMonthTexts.length; i++) {
          let colorLegend = scenarioMonthTexts.item(i).style.color;
          colorLegend = colorLegend.replace(/\(|\)/g, '').replace("rgb", "")
          colorLegend = JSON.parse("[" + colorLegend + "]");

          const text = scenarioMonthTexts.item(i).textContent; //width 60px
          doc.setTextColor(colorLegend[0],colorLegend[1],colorLegend[2]);
          doc.setFontSize(10);
          doc.setFont('Lato-Regular', 'normal');
          doc.text("—"+text, 297-15-25, lineLegendMonth);
          lineLegendMonth = lineLegendMonth + 5;
        }

        // Info Footer variables selecteds 
        doc.setFontSize(10);
        doc.setTextColor(96,96,96);
        doc.setFont('Lato-Bold', 'bold');
        doc.text(seasonTitle, 10+40, heightImgMap+33+13, 'center');
        doc.text(variableTitle, 10+80, heightImgMap+33+13, 'center');
        doc.text(modelTitle, 10+120, heightImgMap+33+13, 'center');
        doc.text(scenarioTitle, 10+160, heightImgMap+33+13, 'center');
        doc.text(periodTitle, 10+200, heightImgMap+33+13, 'center');
        doc.text(valueTitle, 10+240, heightImgMap+33+13, 'center');
        doc.setFontSize(8);
        doc.setFont('Lato-Regular', 'normal');
        doc.text(season, 10+40, heightImgMap+33+13+5, 'center');
        doc.text(variable, 10+80, heightImgMap+33+13+5, 'center');
        doc.text(model, 10+120, heightImgMap+33+13+5, 'center');
        doc.text(scenario, 10+160, heightImgMap+33+13+5, 'center');
        doc.text(period, 10+200, heightImgMap+33+13+5, 'center');
        doc.text(value, 10+240, heightImgMap+33+13+5, 'center');
      }
      if(yearForExport){
        // New page 
        doc.addPage();
        // Logos 
        doc.addImage(logo_brio, 'PNG', 10, 5, 36.37, 12,  NaN, 'FAST');
        doc.addImage(logo_afd, 'PNG', 297-10-37.23, 5, 37.23, 12,  NaN, 'FAST');
        doc.addImage(logo_meteo, 'PNG', 297-10-37.23-5-25.2, 5, 25.2, 12,  NaN, 'FAST');
        doc.addImage(logo_coi, 'PNG', 297-10-37.23-5-25.2-5-35.64, 5, 35.64, 12,  NaN, 'FAST');

        doc.setTextColor(96,96,96);
        doc.setFontSize(18);
        doc.text(document.getElementById('title_graphs').textContent, 150, 27, 'center');
        doc.setFontSize(15);
        doc.text(vm.getTranslation("titleYearly"), 150, 36, 'center');
        const heightImgYear = calculateHeightAspectRatioFitByWidth(widthYear,heightYear, 220);

        doc.addImage(yearURL, 'JPEG', 32, 43, 220, heightImgYear, NaN, 'SLOW');
        doc.addImage(canvasYearURL, 'PNG', 32, 43, 220, heightImgYear, NaN, 'SLOW');

        var lineLegendYear = 43;
        for (var j = 0; j < scenarioYearTexts.length; j++) {
          let colorLegend = scenarioYearTexts.item(j).style.color;
          colorLegend = colorLegend.replace(/\(|\)/g, '').replace("rgb", "");
          colorLegend = JSON.parse("[" + colorLegend + "]");
          let divLine = scenarioYearTexts.item(j).firstElementChild;

          const text = scenarioYearTexts.item(j).textContent; //width 60px
          doc.setTextColor(colorLegend[0],colorLegend[1],colorLegend[2]);
          doc.setFontSize(10);
          doc.setFont('Lato-Regular', 'normal');
          doc.text((divLine.style.borderBottomStyle === "dotted"?"···":"—")+text, 297-15-25, lineLegendYear);
          lineLegendYear = lineLegendYear + 5;
        }
        // Info Footer variables selecteds 
        doc.setFontSize(10);
        doc.setTextColor(96,96,96);
        doc.setFont('Lato-Bold', 'bold');
        doc.text(seasonTitle, 10+40, heightImgMap+33+13, 'center');
        doc.text(variableTitle, 10+80, heightImgMap+33+13, 'center');
        doc.text(modelTitle, 10+120, heightImgMap+33+13, 'center');
        doc.text(scenarioTitle, 10+160, heightImgMap+33+13, 'center');
        doc.text(periodTitle, 10+200, heightImgMap+33+13, 'center');
        doc.text(valueTitle, 10+240, heightImgMap+33+13, 'center');
        doc.setFontSize(8);
        doc.setFont('Lato-Regular', 'normal');
        doc.text(season, 10+40, heightImgMap+33+13+5, 'center');
        doc.text(variable, 10+80, heightImgMap+33+13+5, 'center');
        doc.text(model, 10+120, heightImgMap+33+13+5, 'center');
        doc.text(scenario, 10+160, heightImgMap+33+13+5, 'center');
        doc.text(period, 10+200, heightImgMap+33+13+5, 'center');
        doc.text(value, 10+240, heightImgMap+33+13+5, 'center');
      }

      doc.save(namePDF);
      document.body.removeChild(mapElement);
    });
  }

  getItemPropInArray = (array, nameClass, prop) => {
    const [selecteds] =this.context;
    const item = array.find(dataItem => dataItem.code === selecteds[nameClass]);
    return item[prop];
  }
  
  getScales = (array, abs) => {
    const [selecteds] =this.context;
    const item = array.find(dataItem => dataItem.code === selecteds.variable);
    return item.scales[abs];
  }

  initMap() {
    var vm = this;
    this.layerDivision = (this.layerDivision = L.geoJson(vm.props.mapDivisions, this.styleGeoDivisions()));

    const vistaSatelite = L.tileLayer(
        "//server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Sources: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
        }
      ),
      vistaMapa = L.tileLayer(
        "//server.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Sources: Esri, HERE, Garmin, USGS, Intermap, INCREMENT P, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), MapmyIndia, © OpenStreetMap contributors, and the GIS User Community",
        }
      ),
      vistaTopo = L.tileLayer(
        "//server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Sources: Esri, HERE, Garmin, Intermap, INCREMENT P, GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), swisstopo, MapmyIndia, © OpenStreetMap contributors, GIS User Community",
        }
      );

    var baseLayers = {};
    baseLayers[this.getTranslation("satelitalView")] = vistaSatelite;
    baseLayers[this.getTranslation("streetView")] = vistaMapa;
    baseLayers[this.getTranslation("topoView")] = vistaTopo;

    const optionsMap = {
      zoom: 5,
      layers: [baseLayers[this.getTranslation(vm.props.baselayer)]],
      center: [-15, 51.63]
    };

    this.map = (this.map = L.map("map_gbl", optionsMap));
    this.map.on('baselayerchange', function (e) {
      vm.props.onChangeBaselayer(vm.getTranslation(e.name, true));
    });
    this.map.options.minZoom = 4;
    this.map.options.maxZoom = 17;
    this.map.zoomControl.setPosition("topright");
    this.layer_map = (this.layer_map = L.layerGroup());
    this.layer_map.addTo(this.map);
    L.control.layers(baseLayers).setPosition("topright").addTo(this.map);
    this.popup = L.popup({ closeOnClick: false,autoClose: false});
  }

  async fillLayerMap() {
    var vm = this;
    vm.layer_map.clearLayers();
    if (!isEmpty(vm.props.mapData) && vm.props.mapData.hasOwnProperty('type')) {
        vm.geoMap = L.geoJson(vm.props.mapData, vm.styleMapData());
        vm.layer_map.addLayer(vm.geoMap);
        vm.geoMap.on('click', function(e) {
          vm.latlng = e.latlng;
          vm.onClickMap({lat:e.latlng.lat,lon:e.latlng.lng}, 'lat : '+e.latlng.lat.toFixed(2)+', lon : '+e.latlng.lng.toFixed(2));
          vm.openPopup(e.latlng);
        });
        vm.layer_map.addLayer(vm.layerDivision);
        const geoMapSectors = L.geoJson(vm.props.mapSectors, vm.styleGeoDivisionsSectors());
        vm.layer_map.addLayer(geoMapSectors);
        geoMapSectors.eachLayer(function (layer) {
            layer._path.id = 'layer-' + layer.feature.properties.code.toLowerCase();
            var buttonSector = document.getElementById('btn_'+(layer.feature.properties.code).toLowerCase());
            buttonSector.onmouseenter = function(){
              layer.bindPopup(layer.feature.properties[vm.language === "EN"? "name_en":"name"], {className: 'leaflet-hover-popup'}).openPopup();
              layer.setStyle({
                weight: 2.5,
                dashArray: "5, 10",
                color: "#059496"
              });
            };
            buttonSector.onmouseleave = function(){
              layer.closePopup();
              layer.setStyle({
                weight: 0.5,
                dashArray: "0",
                color: "#000000"
              });
            };
        });
        
      setTimeout(function () {
        vm.map.invalidateSize(true);
      }, 350);
    }
  }

  openPopup(latlng) {
    this.popup
      .setLatLng(latlng)
      .setContent(this.getTranslation("value")+" :  ")
      .openOn(this.map);
  }

  onClickMap(key, text) {
    this.props.onClickMap(key, text);
  }
  
  styleGeoDivisions(isForExport) {
    return {
        style: function() {            
            return {
                fillColor: "transparent",
                color: "#000000",
                weight: isForExport? 0.8: 0.4,
                className: 'geodivisions'
            };
        }
    };
  }

  styleGeoDivisionsSectors() {
    var vm = this;
    return {
        style: function() {            
            return {
                fillColor: "transparent",
                color: "#000000",
                weight: 0.8
                //className: ''
            };
        },
        onEachFeature: function(feature, layer) {
          var propsFeatures = vm.props.mapSectors.features[vm.props.mapSectors.features.findIndex(x => x.properties.code === feature.properties.code)].properties;
          var headerSectors = document.getElementById('h_sectors');
          layer.on('mousemove', function(e) {
              vm.popupHover = L.popup({className: 'leaflet-hover-popup'}).setLatLng(e.latlng).setContent(propsFeatures[vm.props.language === "EN"? "name_en":"name"]).openOn(vm.map);
              this.setStyle({
                weight: 2.5,
                dashArray: "5, 10",
                color: "#059496"
              });
              headerSectors.classList.add(propsFeatures.code.toLowerCase());
          });
          layer.on('mouseout', function(e) {
            if(!this.getElement().classList.contains('active')) {
              if(vm.popupHover !== undefined)vm.popupHover.removeFrom(vm.map);
              this.setStyle({
                weight: 0.5,
                dashArray: "0",
                color: "#000000"
              });
            }
            headerSectors.classList.remove(propsFeatures.code.toLowerCase());
          });
          layer.on('click', function (e) {
            headerSectors.classList.remove(propsFeatures.code.toLowerCase());
            document.getElementById('btn_'+(propsFeatures.code).toLowerCase()).click();
            const [selecteds, setSelecteds] = vm.context;
            setSelecteds(prevState => ({
              ...prevState,
              sector: propsFeatures.code
            }));
          });

        }
    };
  }

  styleMapData(isForExport) {
    var vm = this;
    return {
        style: function(feature) {
            return {
                fillColor: feature.properties.color,
                stroke: false,
                className: "map-data",
                weight: 0.3,
                opacity: 1,
                color: feature.properties.color,
                fillOpacity: isForExport ? 1 : 1-vm.props.transparencyMap,
            };
        }
    };
  }

  translationBaselayers(){
    var labels = document.querySelectorAll('.leaflet-control-layers-base label div span');
    labels[0].innerHTML = this.getTranslation("satelitalView");
    labels[1].innerHTML = this.getTranslation("streetView");
    labels[2].innerHTML = this.getTranslation("topoView");
  }
  
  async componentDidMount() {
    this.initMap();
    this.fillLayerMap();
  }

  async componentDidUpdate(pProps) {
    const vm = this;
    //const [selecteds] =vm.context;
    if (pProps.isDoubleView !== vm.props.isDoubleView) {
      setTimeout(function () {
        vm.map.invalidateSize(true);
      }, 350);
    }
    if (pProps.mapData !== vm.props.mapData) {
      vm.latlng = undefined;
      vm.val = undefined;
      vm.map.closePopup();
      vm.layer_map.closePopup();
      vm.popup.removeFrom(vm.map);
      vm.forceUpdate();
      vm.fillLayerMap();
    }
    if(pProps.language !== vm.props.language) {
      this.translationBaselayers();
      if(vm.latlng !== undefined && vm.props.val !== null && vm.props.val !== undefined) vm.popup
      .setContent(this.getTranslation("value")+" : "+vm.props.val.toFixed(0)+" "+ (vm.props.unit !== null && vm.props.unit !== undefined ? vm.props.unit: ''));
    }
    if(pProps.widthWindow !== vm.props.widthWindow) {
      setTimeout(function () {
        vm.map.invalidateSize(true);
      }, 350);
    }
    if(pProps.val !== vm.props.val && vm.props.val !== null && vm.props.val !== undefined) vm.popup
          .setContent(this.getTranslation("value")+" : "+vm.props.val.toFixed(0)+" "+ (vm.props.unit !== null && vm.props.unit !== undefined ? vm.props.unit: ''));
  }
}