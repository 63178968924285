import React, { Component } from "react";
import { Navbar, Nav, DropdownButton, Dropdown } from "react-bootstrap";
import brioLogo from "../assets/images/logo-brio.png";

class Header extends Component {

  render() {
    let content = {
      FR: {
        code: "FR",
        classLang: "-fr",
        doc: "Documentation",
        descrip: "Description générale",
        info: "Informations"
      },
      EN: {
        code: "EN",
        classLang: "-en",
        doc: "Documentation",
        descrip: "General description",
        info: "Information"
      }
    };

    this.props.language === "FR"
    ? (content = content.FR)
    : (content = content.EN);
    return (
      <>
        <header className="brio-header">
          <Navbar collapseOnSelect expand="lg" variant="dark">
            <Navbar.Brand>
              <div className="logo-brio-climate-platform">
                <img src={brioLogo} alt="Logo BRIO" />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-center"
            >
              <Nav className="navbar-center">
                <Nav.Link href="https://www.commissionoceanindien.org/ressources-centre-documentation/" target="_blank">
                  {content.doc}
                </Nav.Link>
                <Nav.Link href="https://www.commissionoceanindien.org/portfolio-items/brio/" target="_blank">
                  {content.descrip}
                </Nav.Link>
                <Nav.Link href="https://www.commissionoceanindien.org/brio-conference-presse/" target="_blank">
                  {content.info}
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto navbar-right">
                <Nav.Link href="https://www.commissionoceanindien.org/" target="_blank" className="coi-logo" title="Commission de l'Océan Indien"></Nav.Link>
                <Nav.Link href="https://meteofrance.com/" target="_blank" className="meteofrance-logo" title="METEO FRANCE"></Nav.Link>
                <Nav.Link href="https://www.afd.fr/fr/adaptaction" target="_blank" className="afd-logo" title="Agence Française de développement"></Nav.Link>
                <DropdownButton
                  title={content.code}
                  className={"lang-button lang"+content.classLang}
                  onSelect={e => this.props.handleSetLanguage(e)}
                >
                  <Dropdown.Item key="FR" eventKey="FR" active={this.props.language === "FR" ? true : false } className="drop-fr">FR</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item key="EN" eventKey="EN" active={this.props.language === "EN" ? true : false } className="drop-en">EN</Dropdown.Item>
                </DropdownButton>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
      </>
    );
  }
  componentDidUpdate(pProps){
      //console.log(pProps.language);
  }
  handleClickNotClick = (e) => {
    e.stopPropagation();
  };
}

export default Header;
