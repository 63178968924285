import React, { useContext, useState, useLayoutEffect } from "react";
import Header from "./components/Header";
import Body from "./components/Body";
import Loading from "./components/Loading";
import {Provider, Context} from "./services/Context";

function App() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "FR"
  );
  const widthWindow = useWindowSize();
  return (
    <>
      <Loading />
      <Header
        language={language}
        handleSetLanguage={language => {
          setLanguage(language);
          storeLanguageInLocalStorage(language);
        }}
      />
      <Provider>
        <Container language={language} widthWindow={widthWindow}/>
      </Provider>
      <footer><div>Powered by <a href="https://www.ecoclimasol.com/" target="_blank" rel="noopener noreferrer">Ecoclimasol</a></div></footer>
    </>
  );
}

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}

const Container = (props) => {
  const [selecteds] = useContext(Context);
  return <Body language={props.language} parametersMap={selecteds} widthWindow={props.widthWindow}/>;
};

export default App;
