import React, { Fragment, useContext, useState } from "react";
import { DropdownButton, Dropdown, ButtonGroup, Modal, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import {Context} from "../../services/Context";
import { API_URL_BRIO } from "../../services/Api";
import exportsImage from "../../assets/images/exports.png";

export default function BodyHeader(props) {
  const [showModal, setShowModal] = useState(false);
  const [selecteds, setSelecteds] = useContext(Context);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const dict = {
    FR: {
      code: "FR",
      title: "title",
      name: "name",
      modalTitle: "Modal.Title",
      modalBody: "Modal.Body",
      download: "Téléchargements",
      rawData: "Données brutes",
      rawTooltipPxl: "Pixel non selectionné",
      rawTooltipPoint: "Point de station non selectionné",
      rawTooltipNoData: "Données non disponibles"
    },
    EN: {
      code: "EN",
      title: "title_en",
      name: "name_en",
      modalTitle: "Modal.Title",
      modalBody: "Modal.Body",
      download: "Downloads",
      rawData: "Raw data",
      rawTooltipPxl: "Pixel not selected",
      rawTooltipPoint: "Station point not selected",
      rawTooltipNoData: "Data not available"
    }
  };
  const content = dict[props.language];

  const handleSelectSector = (e) => {
    if(e !== selecteds.sector){
      var parametersSector = props.inputOptions.sectors.data[getIndexSector(e)];
      var type = mapTypeExistInSector(e) ? selecteds.type : parametersSector.map_types.data[0].code;
      var variable = setVaribleIfExistInType(type, parametersSector);
      setSelecteds(prevState => ({
          ...prevState,
          sector: e,
          variable: variable,
          type: type
      }));
    }
  }, handleSelectSeason = (e) => {
    if(e !== selecteds.season){
      setSelecteds(prevState => ({
          ...prevState,
          season: e
      }))
    }
  }, handleSelectVariable = (e) => {
    if(e !== selecteds.variable){
      setSelecteds(prevState => ({
          ...prevState,
          variable: e
      }))
    }
  }, handleSelectModel = (e) => {
    if(e !== selecteds.model){
      setSelecteds(prevState => ({
          ...prevState,
          model: e 
      }))
    }
  }, handleSelectScenario = (e) => {
    if(e !== selecteds.scenario){
      setSelecteds(prevState => ({
          ...prevState,
          scenario: e 
      }))
    }
  }, handleSelectPeriod = (e) => {
    if(e !== selecteds.period){
      setSelecteds(prevState => ({
          ...prevState,
          period: e
      }))
    }
  }, handleSelectAnomalies = (e) => {
    if(e !== selecteds.anom){
      setSelecteds(prevState => ({
        ...prevState,
        anom: e
      }))
    }
  }, handleShowModal = () => {
    handleShow();
  }

  const setVaribleIfExistInType = (type, parametersSector) => {
    const variablesArray = parametersSector.variables.data;
    var found = false;
    var secondVariable = '';
    for(var i = 0; i < variablesArray.length; i++) {
        if (variablesArray[i].code === selecteds.variable && variablesArray[i].map_types.includes(type)) {
          found = true;
          break;
        } else if (variablesArray[i].map_types.includes(type) && secondVariable === '') {
          secondVariable = variablesArray[i].code;
        }
    }
    return found ? selecteds.variable : secondVariable === '' ? selecteds.variable: secondVariable;
  }

  const mapTypeExistInSector = (sector) => {
    const mapTypesArray = props.inputOptions.sectors.data[getIndexSector(sector)].map_types.data;
    var found = false;
    for(var i = 0; i < mapTypesArray.length; i++) {
        if (mapTypesArray[i].code === selecteds.type) {
            found = true;
            break;
        }
    }
    return found;
  }
  
  const sortedSectors = props.inputOptions.sectors.data.sort(function(a, b) {
    var textA = a[content.name].toUpperCase();
    var textB = b[content.name].toUpperCase();
    if(textA === textB) return 0;
    if (a.code === "GBL") return -1;
    if (b.code === "GBL") return 1;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  const getItemNameInArray = (array, nameClass, capitalize = false) => {
    const item = array.find(dataItem => dataItem.code.toString() === selecteds[nameClass]);
    return capitalize ? item[content.name].charAt(0).toUpperCase() + item[content.name].slice(1): item[content.name];
  }

  const getTitleVariable = () => {
    var index = getIndexSector(selecteds.sector);
    const originalName = getItemNameInArray(props.inputOptions.sectors.data[index].variables.data, 'variable', true);
    return props.inputOptions.sectors.data[index].variables[content.title]+" ("+originalName+")";
  }

  const getIndexSector = (sector) => {
    return props.inputOptions.sectors.data.findIndex(x => x.code === sector);
  }

  const handleExportMap = () => {
    const urlTiff = API_URL_BRIO+'map/?export&type='+selecteds.type+'&sector='+
    `${selecteds.sector}&season=${selecteds.season}&variable=${selecteds.variable}&scenario=${selecteds.scenario}&period=${selecteds.period}`+(selecteds.anom === "1"?'&anom':'');
    window.open(urlTiff, "_blank");
  }

  const handleExportRawData = () => {
    window.open(props.rawUrl, "_blank");
  }
  const variablesData = props.inputOptions.sectors.data[getIndexSector(selecteds.sector)].variables.data;
  const arrayNoCatVariables = JSON.parse(JSON.stringify(variablesData)).filter(function(x){return !x.hasOwnProperty('category') && x.map_types.includes(selecteds.type)})
  const arraysCategoriesVariables = JSON.parse(JSON.stringify(variablesData)).filter(function(x){return x.hasOwnProperty('category') && x.map_types.includes(selecteds.type)});
  const arrayCats = {};
  for (const cat of props.inputOptions.sectors.data[getIndexSector(selecteds.sector)].categories) {
    arrayCats[cat.id] = JSON.parse(JSON.stringify(arraysCategoriesVariables)).filter(function(x){return x.category === cat.id})
  }
  
  return (
    <div className="header">
      <div className="header-regions" id="h_sectors">
      <Nav variant="pills" defaultActiveKey={selecteds.sector} onSelect={handleSelectSector}>
          {sortedSectors.map((dataItem) =>
            <Nav.Item key={dataItem.code}>
                <Nav.Link id={'btn_'+(dataItem.code).toLowerCase()} eventKey={dataItem.code} className={ dataItem.code === 'GBL' ? 'global-button' : false }>{dataItem[content.name]}</Nav.Link>
            </Nav.Item>
          )} 
        </Nav>
      </div>
      <div className="header-variables-export">
        <div className="header-variables">
          {(props.widthWindow < 1600 && (getItemNameInArray(props.inputOptions.seasons.data, 'season', true)).length > 10) || (props.widthWindow < 1480 && (getItemNameInArray(props.inputOptions.seasons.data, 'season', true)).length > 6) || props.widthWindow < 1360? 
          <OverlayTrigger placement="top-end" overlay={<Tooltip>{props.inputOptions.seasons[content.title]+" ("+getItemNameInArray(props.inputOptions.seasons.data, 'season', true)+")"}</Tooltip>}>
            <DropdownButton
              as={ButtonGroup}
              title={props.inputOptions.seasons[content.title]+" ("+getItemNameInArray(props.inputOptions.seasons.data, 'season', true)+")"}
              className="season"
              onSelect={handleSelectSeason}
            >
              {props.inputOptions.seasons.data.map((dataItem, i, row) =>
                <Fragment key={dataItem.code}>
                  <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.season ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                  {i + 1 < row.length ? <Dropdown.Divider />: ''}
                </Fragment>
              )}
            </DropdownButton>
          </OverlayTrigger>:
          <DropdownButton
            as={ButtonGroup}
            title={props.inputOptions.seasons[content.title]+" ("+getItemNameInArray(props.inputOptions.seasons.data, 'season', true)+")"}
            className="season"
            onSelect={handleSelectSeason}
          >
            {props.inputOptions.seasons.data.map((dataItem, i, row) =>
              <Fragment key={dataItem.code}>
                <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.season ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                {i + 1 < row.length ? <Dropdown.Divider />: ''}
              </Fragment>
            )}
          </DropdownButton>
          }

          {props.widthWindow < 1600 ?
          <OverlayTrigger placement="top-end" overlay={<Tooltip>{getTitleVariable()}</Tooltip>}>
            <DropdownButton
              as={ButtonGroup}
              title={getTitleVariable()}
              className="variable"
              onSelect={handleSelectVariable}
            >
              {arrayNoCatVariables.map((dataItem, i, row) =>
                  <Fragment key={dataItem.code}>
                    <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.variable ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                    {i + 1 < row.length ? <Dropdown.Divider />: ''}
                  </Fragment>
              )}
              {arraysCategoriesVariables.length ? props.inputOptions.sectors.data[getIndexSector(selecteds.sector)].categories.map((dataCat, j, rowCat) =>
                  {return arrayCats[dataCat.id].length ? 
                    <Fragment key={dataCat.id}>
                      <Dropdown.Divider />
                      <Dropdown bsPrefix={arrayCats[dataCat.id].find(x => x.code === selecteds.variable) !== undefined ? 'active dropdown-cat':'dropdown-cat'}>
                        <Dropdown.Toggle variant="success" id={dataCat.id}>
                          {dataCat[content.name]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {/*<Dropdown.Header>{dataCat[content.name]}</Dropdown.Header>*/}
                          {arrayCats[dataCat.id].map((dataItemC, iC, rowC) =>
                            <Fragment key={dataItemC.code}>
                              <Dropdown.Item key={dataItemC.code} eventKey={dataItemC.code} active={dataItemC.code === selecteds.variable ? true : false } >{dataItemC[content.name]}</Dropdown.Item>
                              {iC + 1 < rowC.length ? <Dropdown.Divider />: ''}
                            </Fragment>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Fragment>: ''}
              ): ''}
            </DropdownButton>
          </OverlayTrigger>:
            <DropdownButton
            as={ButtonGroup}
            title={getTitleVariable()}
            className="variable"
            onSelect={handleSelectVariable}
          >
            {arrayNoCatVariables.map((dataItem, i, row) =>
                <Fragment key={dataItem.code}>
                  <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.variable ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                  {i + 1 < row.length ? <Dropdown.Divider />: ''}
                </Fragment>
            )}
            {arraysCategoriesVariables.length ? props.inputOptions.sectors.data[getIndexSector(selecteds.sector)].categories.map((dataCat, j, rowCat) =>
                {return arrayCats[dataCat.id].length ? 
                  <Fragment key={dataCat.id}>
                    <Dropdown.Divider />
                    <Dropdown bsPrefix={arrayCats[dataCat.id].find(x => x.code === selecteds.variable) !== undefined ? 'active dropdown-cat':'dropdown-cat'}>
                      <Dropdown.Toggle variant="success" id={dataCat.id}>
                        {dataCat[content.name]}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {arrayCats[dataCat.id].map((dataItemC, iC, rowC) =>
                          <Fragment key={dataItemC.code}>
                            <Dropdown.Item key={dataItemC.code} eventKey={dataItemC.code} active={dataItemC.code === selecteds.variable ? true : false } >{dataItemC[content.name]}</Dropdown.Item>
                            {iC + 1 < rowC.length ? <Dropdown.Divider />: ''}
                          </Fragment>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Fragment>: ''}
            ): ''}
          </DropdownButton>
          }
          
          {props.widthWindow < 1600 ?
            <OverlayTrigger placement="top-end" overlay={<Tooltip>{props.inputOptions.model[content.title]+" ("+props.inputOptions.model.data[0][content.name]+")"}</Tooltip>}>
              <DropdownButton
                as={ButtonGroup}
                title={props.inputOptions.model[content.title]+" ("+props.inputOptions.model.data[0][content.name]+")"}
                className="model"
                onSelect={handleSelectModel}
              >
                {/*   Cambiar y poner los modelos
                  props.inputOptions.model.data.map((dataItem, i, row) =>
                  <Fragment key={dataItem.code}>
                    <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.model ? true : false } >{dataItem.name}</Dropdown.Item>
                    {i + 1 < row.length ? <Dropdown.Divider />: ''}
                  </Fragment>
                )*/}
                <Dropdown.Item active={props.inputOptions.model.data[0].name === selecteds.model ? true : false } >{props.inputOptions.model.data[0][content.name]}</Dropdown.Item>
              </DropdownButton>
            </OverlayTrigger>:
            <DropdownButton
              as={ButtonGroup}
              title={props.inputOptions.model[content.title]+" ("+props.inputOptions.model.data[0][content.name]+")"}
              className="model"
              onSelect={handleSelectModel}
            >
              <Dropdown.Item active={props.inputOptions.model.data[0].name === selecteds.model ? true : false } >{props.inputOptions.model.data[0][content.name]}</Dropdown.Item>
            </DropdownButton>
          }
          
          {props.widthWindow < 1500 ?
            <OverlayTrigger placement="top-end" overlay={<Tooltip>{props.inputOptions.scenarios[content.title]+" ("+getItemNameInArray(props.inputOptions.scenarios.data, 'scenario')+")"}</Tooltip>}>
              <DropdownButton
                as={ButtonGroup}
                title={props.inputOptions.scenarios[content.title]+" ("+getItemNameInArray(props.inputOptions.scenarios.data, 'scenario')+")"}
                className="scenario"
                onSelect={handleSelectScenario}
              >
                {props.inputOptions.scenarios.data.map((dataItem, i, row) =>
                  <Fragment key={dataItem.code}>
                    <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.scenario ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                    {i + 1 < row.length ? <Dropdown.Divider />: ''}
                  </Fragment>
                )}
              </DropdownButton>
            </OverlayTrigger>:
            <DropdownButton
              as={ButtonGroup}
              title={props.inputOptions.scenarios[content.title]+" ("+getItemNameInArray(props.inputOptions.scenarios.data, 'scenario')+")"}
              className="scenario"
              onSelect={handleSelectScenario}
            >
              {props.inputOptions.scenarios.data.map((dataItem, i, row) =>
                <Fragment key={dataItem.code}>
                  <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.scenario ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                  {i + 1 < row.length ? <Dropdown.Divider />: ''}
                </Fragment>
              )}
            </DropdownButton>
          }
          
          {props.widthWindow < 1500 ?
            <OverlayTrigger placement="top-end" overlay={<Tooltip>{props.inputOptions.periods[content.title]+" ("+getItemNameInArray(props.inputOptions.periods.data, 'period')+")"}</Tooltip>}>
              <DropdownButton
                as={ButtonGroup}
                title={props.inputOptions.periods[content.title]+" ("+getItemNameInArray(props.inputOptions.periods.data, 'period')+")"}
                className="period"
                onSelect={handleSelectPeriod}
              >
                {props.inputOptions.periods.data.map((dataItem, i, row) =>
                  <Fragment key={dataItem.code}>
                    <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code.toString() === selecteds.period ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                    {i + 1 < row.length ? <Dropdown.Divider />: ''}
                  </Fragment>
                )}
              </DropdownButton>
            </OverlayTrigger>:
            <DropdownButton
              as={ButtonGroup}
              title={props.inputOptions.periods[content.title]+" ("+getItemNameInArray(props.inputOptions.periods.data, 'period')+")"}
              className="period"
              onSelect={handleSelectPeriod}
            >
              {props.inputOptions.periods.data.map((dataItem, i, row) =>
                <Fragment key={dataItem.code}>
                  <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code.toString() === selecteds.period ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                  {i + 1 < row.length ? <Dropdown.Divider />: ''}
                </Fragment>
              )}
            </DropdownButton>
          }



          <DropdownButton
            as={ButtonGroup}
            title={props.inputOptions.anom[content.title]+" ("+getItemNameInArray(props.inputOptions.anom.data, 'anom', true)+")"}
            className="anomaly"
            onSelect={handleSelectAnomalies}
          >
            {props.inputOptions.anom.data.map((dataItem, i, row) =>
              <Fragment key={dataItem.code}>
                <Dropdown.Item key={dataItem.code} eventKey={dataItem.code} active={dataItem.code === selecteds.anom ? true : false } >{dataItem[content.name]}</Dropdown.Item>
                {i + 1 < row.length ? <Dropdown.Divider />: ''}
              </Fragment>
            )}
          </DropdownButton>
        </div>
        <Dropdown className="btn-exports">
          <Dropdown.Toggle variant="secondary">
            <img src={exportsImage}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Header>{content.download}</Dropdown.Header>
            <Dropdown.Item onClick= { () =>
              props.handleExports('pdf')
            }>PDF</Dropdown.Item>
            <Dropdown.Item onClick= { () =>
              props.handleExports('png')
            }>PNG</Dropdown.Item>
            <Dropdown.Item onClick= { () =>
              props.handleExports('svg')
            }>SVG</Dropdown.Item>
            <Dropdown.Item onClick= {handleExportMap}>{selecteds.type === "vec"? 'GeoJSON': 'TIF'}</Dropdown.Item>
            <Dropdown.Divider />
            {props.rawUrl !== '' ? 
              <Dropdown.Item onClick={handleExportRawData}>{content.rawData}</Dropdown.Item>:
              <OverlayTrigger placement="left-start" overlay={<Tooltip>{selecteds.type === "pxl" ? content.rawTooltipPxl: props.isPoint ? content.rawTooltipPoint: content.rawTooltipNoData}</Tooltip>}>
               <span className="dropdown-item disabled">{content.rawData}</span>
              </OverlayTrigger>
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/*<Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{content.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content.modalBody}</Modal.Body>
        {/*<Modal.Footer>
          <Button bsPrefix="btn btn-close" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>*/}
    </div>
  );
}
