import axios from 'axios';

const API_URL_BRIO = './api/';

const brioApiAxios = axios.create({
  baseURL: API_URL_BRIO
});

axios.interceptors.request.use(function(config){
  return config;
}, function(error) {
  console.log(error);
  return Promise.reject(error);
});
axios.interceptors.response.use(function(reponse){
  //console.log("Success");
  return reponse;
}, function(error) {
  alert('An error occurred, please try again later.\n'+error);
  return Promise.reject(error);
});

export {brioApiAxios, API_URL_BRIO};