import React, { Component, useContext } from "react";
import { Modal, Button } from 'react-bootstrap';
import BodyHeader from "./body/BodyHeader";
import Map from "./body/Map";
import GlobalMap from "./body/GlobalMap";
import MonthlyGraph from "./body/graphs/MonthlyGraph";
import YearlyGraph from "./body/graphs/YearlyGraph";
import * as globalConst from "../consts/globalConst";
import {Context} from "../services/Context";
import { trackPromise } from 'react-promise-tracker';
import {brioApiAxios} from "../services/Api";
import graphicIcon from "../assets/images/graphic.png";

const isEmpty = globalConst.isEmpty;

export default class Body extends Component {
  static contextType = Context

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      doubleView: false,
      headerOptions: {},
      titleGraphs:'',
      latlon: {
        lat: null,
        lon: null
      },
      idPlace: null,
      isPoint: false,
      geoJsonData: {},
      shapesSector: {},
      extraGBLGeojson: {},
      sectorsGeojson: {},
      graphs: {
        val: null,
        unit: null,
        monthlyGraph: {},
        yearlyGraph: {}
      },
      rawUrl: '',
      transparencyMap: 0.2,
      baselayer: "satelitalView"
    };
    this.map = React.createRef();
    this.mapG = React.createRef();
  }

  async getHeaderOptionsJson () {
    let res = await trackPromise(brioApiAxios.get());
    this.setState({
      ...this.state,
      headerOptions:res.data
    });
  }

  async getMapGeoJson () {
    var parametersUpdated = JSON.parse(JSON.stringify(this.props.parametersMap));
    await this.setLocation(null);
    if(parametersUpdated.anom === "0" || parametersUpdated.anom === 0) delete parametersUpdated.anom;
    if(this.props.parametersMap.sector === "GBL") {
      parametersUpdated["type"] = "pxl";
      let resData = await trackPromise(brioApiAxios.get('map/',{params: parametersUpdated}).catch(function (error) {
        alert('An error occurred, please select other Variable\n'+ error);
      }));
      if(isEmpty(this.state.extraGBLGeojson) || isEmpty(this.state.sectorsGeojson)) {
        let divisionsData = await trackPromise(brioApiAxios.get('sector/GBL/extra/'));
        let sectorsData = await trackPromise(brioApiAxios.get('sector/'));
        this.setState({
          ...this.state,
          doubleView: false,
          isPoint: false,
          titleGraphs:'',
          shapesSector: {},
          geoJsonData: resData !== undefined ? resData.data : {},
          extraGBLGeojson: divisionsData.data,
          sectorsGeojson: sectorsData.data,
          graphs: {
            val: null,
            unit: null,
            monthlyGraph: {},
            yearlyGraph: {}
          },
          rawUrl: ''
        });
      } else {
        this.setState({
          ...this.state,
          doubleView: false,
          isPoint: false,
          titleGraphs:'',
          shapesSector: {},
          geoJsonData: resData !== undefined ? resData.data : {},
          graphs: {
            val: null,
            unit: null,
            monthlyGraph: {},
            yearlyGraph: {}
          },
          rawUrl: ''
        });
      }
    } else if(this.props.parametersMap.type === "pxl"){
      let resData = await trackPromise(brioApiAxios.get('map/',{params: parametersUpdated}).catch(function (error) {
        alert('An error occurred, please select other Variable\n'+ error);
      }));
      this.setState({
        ...this.state,
        shapesSector: {},
        geoJsonData: resData !== undefined ? resData.data : {},
        isPoint: false,
        graphs: {
          val: null,
          unit: null,
          monthlyGraph: {},
          yearlyGraph: {}
        },
        rawUrl: ''
      });
    } else {
      // Is vector map
      let shapesData = await trackPromise(brioApiAxios.get('sector/'+this.props.parametersMap.sector+'/shapes/'));
      let resData = await trackPromise(brioApiAxios.get('map/',{params: parametersUpdated}).catch(function (error) {
        alert('An error occurred, please select other Variable\n'+ error);
      }));
      if(!resData.data.is_point){
        const filterRegions = shapesData.data.features.filter(function (feature) { return feature.geometry.type !== "Point" });
        shapesData.data['features'] = filterRegions;
      }
      this.setState({
        ...this.state,
        shapesSector: shapesData.data,
        geoJsonData: resData !== undefined ? resData.data : {},
        isPoint: resData.data.is_point,
        graphs: {
          val: null,
          unit: null,
          monthlyGraph: {},
          yearlyGraph: {}
        },
        rawUrl: ''
      });
    }
    //console.log(this.state.headerOptions.sectors.data[this.getIndexSector()]);
    if(this.props.parametersMap.sector !== "GBL" && !this.state.isPoint) {
      this.getGraphics('', this.state.headerOptions.sectors.data[this.getIndexSector()][this.getTranslation("name")])
    } else {
      this.resetData();
    }
  }

  async getGraphics (param, title) {
    var parametersUpdated = JSON.parse(JSON.stringify(this.props.parametersMap));
    if(this.props.parametersMap.sector === "GBL") parametersUpdated["type"] = "pxl";
    if(parametersUpdated.anom === "0" || parametersUpdated.anom === 0) delete parametersUpdated.anom;

    Object.assign(parametersUpdated, param);
    let res = await trackPromise(brioApiAxios.get('chart/', {params: parametersUpdated}));
    this.setState({
      ...this.state,
      titleGraphs: title,
      graphs: {
        val: res.data.val,
        unit: res.data.unit,
        monthlyGraph: typeof res.data.month != "undefined" ? res.data.month: {},
        yearlyGraph: typeof res.data.year != "undefined" && res.data.year !== null ? res.data.year: {}
      },
      rawUrl: res.data.hasOwnProperty('raw_url') ? res.data.raw_url : ''
    });
  }

  setLocation = async (key, text) => {
    if(key !== null) {
      this.setState({
        ...this.state,
        doubleView: true,
        latlon: {
          lat: key.hasOwnProperty('lat')?key.lat:null,
          lon: key.hasOwnProperty('lon')?key.lon:null,
        },
        idPlace: key.hasOwnProperty('id')?key.id:null,
      });
      this.getGraphics(key, text);
    } else {
      this.setState({
        ...this.state,
        titleGraphs:'',
        latlon: {
          lat: null,
          lon: null,
        },
        idPlace: null
      });
    }
  }

  setBaselayer = async (b) => {
      this.setState({
        ...this.state,
        baselayer: b
      });
  }

  setTransparency = async (t) => {
      this.setState({
        ...this.state,
        transparencyMap: t
      });
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      showModal: false
    });
  }

  handleShow = () => {
    this.setState({
      ...this.state,
      showModal: true
    });
  }

  openGraph = () => {
    if((this.props.parametersMap.type === 'pxl' && this.state.latlon.lat !== null) || (this.props.parametersMap.type === 'vec' && this.state.idPlace !== null)) {
      this.setLocation(null);
      this.getGraphics('', this.state.headerOptions.sectors.data[this.getIndexSector()][this.getTranslation("name")]);
    }
    if(!this.state.doubleView) this.setState({doubleView: true});
  }

  openCloseGraph () {
    let dv = false;
    if(!isEmpty(this.state.geoJsonData) && !isEmpty(this.state.graphs.yearlyGraph)) {
      dv = this.state.doubleView ? false: true;
    } else {
      this.handleShow();
    }
    this.setState({doubleView: dv});
    //localStorage.setItem("doubleView", dv);
  }

  componentDidMount() {
    this.getHeaderOptionsJson();
    this.getMapGeoJson();
  }

  async resetData() {
    this.setState({
      ...this.state,
      doubleView: false,
      titleGraphs:'',
      latlon: {
        lat: null,
        lon: null,
      },
      idPlace: null,
      graphs: {
        val: null,
        unit: null,
        monthlyGraph: {},
        yearlyGraph: {}
      },
      rawUrl: ''
    });
  }

  componentDidUpdate(pProps){
    //if(pProps.parametersMap.sector !== this.props.parametersMap.sector && this.state.isPoint) this.setState({...this.state, doubleView: false});
    if (JSON.stringify(pProps.parametersMap) !== JSON.stringify(this.props.parametersMap)) {
      //console.log("Dif parametersMap");
      this.resetData();
      this.getMapGeoJson();
    }
    if (pProps.language !== this.props.language) {
      if((this.props.parametersMap.type === 'pxl' && this.state.latlon.lat === null) || (this.props.parametersMap.type === 'vec' && this.state.idPlace === null)) {
        this.setState({
          ...this.state,
          titleGraphs: this.state.headerOptions.sectors.data[this.getIndexSector()][this.getTranslation("name")],
        });
      }
    }
  }

  getIndexSector() {
    const [selecteds] =this.context;
    return this.state.headerOptions.sectors.data.findIndex(x => x.code === selecteds.sector);
  }

  getTranslation(key) {
    let contentLang = {
    FR: {
        code: "FR",
        name: "name",
        titlePopupNolocation: "Aucun emplacement sélectionné",
        textPopupNolocation: "Veuillez sélectionner un emplacement sur la carte pour afficher les séries temporelles respectives.",
        closeButton: "Fermer"
    },
    EN: {
        code: "EN",
        name: "name_en",
        titlePopupNolocation: "No location selected",
        textPopupNolocation: "Please select a location on the map to display the respective temporal series.",
        closeButton: "Close"
    }
    };
    return contentLang[this.props.language][key];
  }

  openGraphSector = () => {
    this.map.current.handleOpenGraphsSector();
  };

  exportTo = (format) => {
    if(this.props.parametersMap.sector === "GBL" && this.state.isPoint === false) {
      this.mapG.current.exportTo(format);
    } else {
      this.map.current.exportTo(format);
    }
  };

  render() {
    return (
      <main className="page-content">
        <div className="container-header">
            { isEmpty(this.state.headerOptions) ?
              <div className="header">
                <div className="header-regions"> {
                [...Array(9)].map((item, index) => (
                  <div key={index} className="nav-item"><span className="nav-link"></span></div>
                ))}
                </div>
                <div className="header-variables-export">
                <div className="header-variables"> {
              [...Array(6)].map((item, index) => (
                <div key={index} role="group" className="dropdown btn-group"></div>
              ))}
                </div>
                </div>
              </div>
              :<BodyHeader 
              inputOptions={this.state.headerOptions} 
              language={this.props.language} 
              widthWindow={this.props.widthWindow} 
              handleExports={this.exportTo} 
              rawUrl={this.state.rawUrl}
              isPoint={this.state.isPoint}/>
            }
            {/* Agregar dropdown button para telechargements */}
        </div>
        <div className="container-body">
          <div className="body">
            <div
              className={
                "container map " + (this.state.doubleView ? "sync-map" : "full-map")
              }
            >
              {
                isEmpty(this.state.headerOptions) || isEmpty(this.state.geoJsonData) ?
                <div id="map"></div>:
                this.props.parametersMap.sector === "GBL" && this.state.isPoint === false ?
                <GlobalMap
                ref={this.mapG}
                isDoubleView={this.state.doubleView}
                baselayer={this.state.baselayer}
                onChangeBaselayer={this.setBaselayer}
                mapData={this.state.geoJsonData}
                mapDivisions={this.state.extraGBLGeojson}
                mapSectors={this.state.sectorsGeojson}
                sectorParameters={this.state.headerOptions.sectors.data[this.getIndexSector()]}
                inputOptions={this.state.headerOptions}
                onClickMap={this.setLocation}
                val={this.state.graphs.val}
                unit={this.state.graphs.unit}
                language={this.props.language}
                changeTransparency={this.setTransparency}
                transparencyMap={this.state.transparencyMap}
                widthWindow={this.props.widthWindow}
                />:
                <Map
                ref={this.map}
                isDoubleView={this.state.doubleView}
                baselayer={this.state.baselayer}
                onChangeBaselayer={this.setBaselayer}
                handleOpenGraphs={this.openGraph}
                mapData={this.state.geoJsonData}
                mapShapes={this.state.shapesSector}
                sectorParameters={this.state.headerOptions.sectors.data[this.getIndexSector()]}
                inputOptions={this.state.headerOptions}
                onClickMap={this.setLocation}
                val={this.state.graphs.val}
                unit={this.state.graphs.unit}
                language={this.props.language}
                titleGraphs={this.state.titleGraphs}
                isSectorGraphs={(this.props.parametersMap.type === 'pxl' && this.state.latlon.lat === null) || (this.props.parametersMap.type === 'vec' && this.state.idPlace === null)}
                isPoint={this.state.isPoint}
                changeTransparency={this.setTransparency}
                transparencyMap={this.state.transparencyMap}
                widthWindow={this.props.widthWindow}
                />
              }
            </div>
            <div
              className={
                "container graphic " +
                (this.state.doubleView ? "opened-graph" : "closed-graph")
              }
            >
              <div className="container-icon-open-close" onClick={() => {this.openCloseGraph()}}>
                <span className="icon-open"></span>
                <span className="icon-close"></span>
              </div>
              { isEmpty(this.state.headerOptions) ?
              <div className="graphic"></div>:
              <GraphsContainer 
                place={this.props.parametersMap.sector === "GBL" || this.props.parametersMap.type === "pxl" ? this.state.latlon:this.state.idPlace} 
                periodsData={this.state.headerOptions.periods.data} 
                titleGraphs={this.state.titleGraphs} 
                graphs={this.state.graphs} 
                doubleView={this.state.doubleView} 
                language={this.props.language}
                isSectorGraphs={(this.props.parametersMap.type === 'pxl' && this.state.latlon.lat === null) || (this.props.parametersMap.type === 'vec' && this.state.idPlace === null)}
                handleOpenGraphsSector={this.openGraphSector}
                isPoint={this.state.isPoint}
              />
              }
              <div className="container-icon-graphic" onClick={() => {this.openCloseGraph()}}>
                <img src={graphicIcon} alt="Graph Brio" />
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.getTranslation('titlePopupNolocation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.getTranslation('textPopupNolocation')}</Modal.Body>
          <Modal.Footer>
            <Button bsPrefix="btn btn-close" onClick={this.handleClose}>
              {this.getTranslation('closeButton')}
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    );
  }
}


const GraphsContainer = (props) => {
  const [selecteds] = useContext(Context);
  let contentLang = {
    FR: {
      code: "FR",
      name: "name",
      valLocation: "Valeurs pour l'emplacement",
      valLocationIsland: "Valeurs pour l'île",
      islandGraphsButton: "Voir Graphs de l'île"
    },
    EN: {
      code: "EN",
      name: "name_en",
      valLocation: "Values for current location",
      valLocationIsland: "Values for the island",
      islandGraphsButton: "View Island Graphs"
    }
  };
  contentLang = contentLang[props.language];
  const getItemNameInArray = (array, nameClass) => {
    const item = array.find(dataItem => dataItem.code.toString() === selecteds[nameClass]);
    return item[contentLang.name];
  }
  const period = getItemNameInArray(props.periodsData, 'period');
  const titleGraphsValues = (selecteds.type === 'pxl' && props.place.lat === null) || (selecteds.type === 'vec' && props.place === null) ? contentLang.valLocationIsland:contentLang.valLocation;
  const seriesYearly = isEmpty(props.graphs.yearlyGraph) ? {} :
  JSON.parse('{"series": '+ JSON.stringify(props.graphs.yearlyGraph.series) +',"series_en": ' + JSON.stringify(props.graphs.yearlyGraph.series_en) + '}');
  return (
    <div className="graphic">
      <div id="title_graphs">{props.titleGraphs !== '' ? `${titleGraphsValues} (${props.titleGraphs})`:''}</div>
      {selecteds.sector === 'GBL' || props.isPoint ? '':
        <Button
        variant="primary"
        disabled={props.isSectorGraphs? true:false}
        className="btn-graphs-island"
        onClick= { () =>
          props.handleOpenGraphsSector()
        }
        >
          {contentLang.islandGraphsButton}
        </Button>
      }
      {Object.keys(props.graphs.yearlyGraph).length === 0 && props.graphs.yearlyGraph.constructor === Object ?
        <div className="container-graphic loading-charts"><h2>Loading</h2></div>:
        <>{isEmpty(props.graphs.monthlyGraph) || typeof props.graphs.monthlyGraph === "undefined" || typeof props.graphs.monthlyGraph === undefined ?
            '':
            <MonthlyGraph
            language={props.language}
            graphicsData={props.graphs.monthlyGraph}
            isDoubleView={props.doubleView}
            unit={props.graphs.unit}
            periodString={period}
            place={props.place}/>
          }
          <YearlyGraph
          language={props.language}
          graphicsData={props.graphs.yearlyGraph}
          series={seriesYearly}
          isDoubleView={props.doubleView}
          unit={props.graphs.unit}
          place={props.place}/>
        </>
      }
    </div>
  );
};